<template>
  <v-card id="stock_card">
    <v-list-item two-line>
      <v-list-item-avatar  :color="avatar_bg" size="40">
        <!-- ทำไมถึงใส่เครือ่งหมาย : ที่ color เพื่อให้ props สามัญรู้ว่าจะมีการ bind ผูก ค่าจากตัวเข้ามา ไม่งั้นจะมองเป็น string -->
        <v-icon color="white" size="20">{{ avatar_icon }}</v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title class="subtitle-2 grey--text text--darken-2">{{
          title
        }}</v-list-item-title>
        <v-list-item-subtitle class="subtitle-1" v-if="total"
          >รวม {{ total | currency("", 0) }} |
          {{ amount | currency("฿", 0) }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import api from "@/services/api";
import _ from "lodash";

export default {
  name: "OrderCodTotalCard",
  props: ["title", "avatar_bg", "avatar_icon", "type", "status", "transporter"], //การกำหนด properties ให้กับ components
  data() {
    return {
      total: 0,
      amount: 0
    };
  },
  methods: {
    async getCodTotal() {
      let result = "";
      if (this.status == "total") {
        result = await api.getOrderCODTotal(this.transporter);
      } 
      if (result.data.result == "ok") {
        this.total = result.data.message.total;
        this.amount = result.data.message.amount;
      }
    },
    async getCodPending() {
      let result = "";
      if (this.status == "total") {
        result = await api.getOrderCODTotalPending(this.transporter);
      }

      if (result.data.result == "ok") {
        this.total = result.data.message.total;
        this.amount = result.data.message.amount;
      }
    },
    async getCodPaid() {
      let result = "";
      if (this.status == "total") {
        result = await api.getOrderCODTotalPaid(this.transporter);
      } 
      if (result.data.result == "ok") {
        this.total = result.data.message.total;
        this.amount = result.data.message.amount;
      }
    },
    loadData() {
      if (this.type == "total") {
        this.getCodTotal();
      } else if (this.type == "pending") {
        this.getCodPending();
      } else if (this.type == "paid") {
        this.getCodPaid();
      }
    }
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style></style>
