<template>
  <v-container fluid grey lighten-3>
    <!-- Summary section -->

    <v-snackbar
      v-model="snackbar.active"
      top
      color="yellow darken-2"
      :timeout="3000"
    >
      <v-icon light small class="mr-1">check_circle</v-icon>
      <b class="black--text">{{ snackbar.msg }}</b>
      <v-btn light text @click="snackbar.active = false">
        ปิด
      </v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarFaild.active"
      top
      color="error"
      :timeout="3000"
    >
      <v-icon dark small class="mr-1">cancel</v-icon>
      <b>{{ snackbarFaild.msg }}</b>
      <v-btn dark text @click="snackbarFaild.active = false">
        ปิด
      </v-btn>
    </v-snackbar>

    <v-row class="mt-5">
      <v-col>
        <h3 class="title">
          <v-icon class="mr-1">mdi-cloud-outline</v-icon> COD รายการที่รอโอน
        </h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col lg="4" md="6" sm="12" cols="12">
        <OrderCodTotalCard
          title="COD รอโอน"
          avatar_bg="red"
          avatar_icon="cloud_queue"
          type="pending"
          status="total"
          ref="codCard"
          :transporter="filterTransporter"
        />
      </v-col>

      <v-col lg="4" md="6" sm="12" cols="12">
        <OrderCodTotalCard
          title="COD โอนแล้ว"
          avatar_bg="purple"
          avatar_icon="mdi-cloud-check"
          type="paid"
          status="total"
          ref="paidCard"
          :transporter="filterTransporter"
        />
      </v-col>

      <v-col lg="4" md="6" sm="12" cols="12">
        <OrderCodTotalCard
          title="COD TOTAL"
          avatar_bg="blue"
          avatar_icon="mdi-credit-card-plus"
          type="total"
          status="total"
          ref="totalCard"
          :transporter="filterTransporter"
        />
      </v-col>
    </v-row>

    <!-- Table section -->

    <!-- filter brand and date picker -->
    <v-row>
      <v-col>
         <v-select
            v-model="filterTransporter"
            :items="transporterMenuAction"
            label="บริษัทขนส่ง"
            item-text="title"
            item-value="actionType"
            @change="selectTransporter"
          ></v-select>
      </v-col>
    </v-row>

    <v-card class="mt-5">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="mDataArray"
        :options.sync="options"
        :loading="dataTableLoading"
        :server-items-length="totalItems"
        :footer-props="{ 'items-per-page-options': [50, 100, 200] }"
        show-select
        disable-filtering
        disable-sort
        mobile-breakpoint="200"
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="yellow darken-1" light height="70">
            <v-badge size="18" color="blue lighten-1">
              <template v-slot:badge>
                <span class="black--text">{{ selected.length }}</span>
              </template>
              เลือก
            </v-badge>
            <v-divider class="mx-10" inset vertical=""></v-divider>
            <v-text-field
              v-model.trim="search"
              name="search"
              label="พิมพ์ ชื่อ | เบอร์โทร | Tracking"
              id="search"
              append-icon="search"
              single-line
              hide-details
              autocomplete="off"
            ></v-text-field>

            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <v-menu subheader>
              <template v-slot:activator="{ on }">
                <v-btn class="mb-1" icon light v-on="on">
                  <v-icon class="ml-1">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-subheader>เมนู</v-subheader>
                <v-list-item @click="updatePayment">
                  <v-list-item-icon><v-icon>mdi-beer</v-icon></v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Update Payment Info</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                  <v-divider></v-divider>
                <v-subheader>เปลี่ยน สถานะ</v-subheader>
                <v-list-item
                  v-for="(item, index) in itemsMenuAction"
                  :key="index"
                  @click="doActionMenu(item)"
                > 
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </template>

        <template v-slot:item="{ item, select, isSelected }">
          <tr>
            <td>
              <v-simple-checkbox
                @input="select"
                :value="isSelected"
                :ripple="false"
              ></v-simple-checkbox>
            </td>
            <td>
              <Transporter :id="item.transporter"/>
              {{ item.id }}</td>

            <td class="pt-2 pb-2">
              <div class="my-td-width3">
                <OrderStatus :status="item.status"></OrderStatus>
                <br />
                <template v-if="item.tracking">
                  <span class="caption">{{ item.tracking }}</span>
                  <v-tooltip top close-delay="200" :key="item.id">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        color="grey"
                        text
                        x-small
                        @click.prevent="copyTracking(item.tracking)"
                        ><v-icon x-small>mdi-content-copy</v-icon></v-btn
                      >
                    </template>
                    <span>Copy!</span>
                  </v-tooltip>
                </template>
              </div>
            </td>

            <td>
              <v-chip
                label
                :dark="true"
                outlined
                color="orange darken-4"
                small
                v-if="item.cod_status == 0"
              >
                รอโอน
              </v-chip>
            </td>

            <td>
              <v-chip
                color="green darken-3"
                :dark="true"
                v-if="item.is_cod == paymentMethods[1].id"
                class="ma-2 pa-1"
                small
                outlined
              >
                <b>COD{{ item.amount | currency(" ฿", 0) }}</b>
              </v-chip>
              <v-chip light v-else class="ma-2 pa-1" outlined small>
                PAID {{ item.amount | currency("฿", 0) }}
              </v-chip>

              <div v-if="item.cod_status == 1" class="caption">
                <v-icon size="12" color="blue darken-1">check_circle</v-icon>
                paid
                {{ item.cod_paid | moment("YYYY-MM-DD") }}
              </div>
            </td>

            <td class="caption">
              {{ item.branch }}
            </td>
            <td class="pt-2 pb-2">
              <v-chip
                class="ml-1"
                label
                :dark="true"
                outlined
                color="blue darken-3"
                small
                v-if="item.tag"
              >
                {{ item.tag }}
              </v-chip>
              {{ item.name }}
              <div class="caption my-td-width2">
                <v-icon color="blue lighten-2" size="15">mdi-phone</v-icon>
                {{ item.tel1 }}, {{ item.tel2 }}
              </div>
              <div v-if="item.remark_admin">
                <v-icon size="15" color="orange darken-2">error_outline</v-icon>
                <span
                  class="caption grey--text text--darken-3 ml-1 yellow lighten-2"
                  >{{ item.remark_admin }}</span
                >
              </div>
              <div v-if="item.tags">
                <v-chip
                  small
                  v-for="(tag, tindex) in item.tags.item"
                  :key="tindex"
                  class="mr-1"
                  >{{ tag.name }}</v-chip
                >
              </div>
            </td>

            <td class="caption">
              <div class="my-td-width">
                <v-icon color="orange" small>mdi-update</v-icon
                >{{ item.createdAt | moment("DD-MM-YYYY HH:mm") }}
                <span v-if="item.createdAt != item.updatedAt"
                  ><br /><v-icon color="green" small>mdi-update</v-icon
                  >{{ item.updatedAt | moment("DD-MM-YYYY HH:mm") }}</span
                >
              </div>
            </td>
            <td>
              <span class="orange--text text--darken-4">{{
                item.createdAt | moment("from")
              }}</span>
            </td>
            <td>
              <v-icon size="18" class="mr-1" @click="orderIdSheet = item.id"
                >airplay</v-icon
              >
              <v-icon size="18" class="mr-2" @click="editItem(item)"
                >search</v-icon
              >
              <v-icon size="18" class="mr-1" @click="deleteItem(item)"
                >delete</v-icon
              >
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="confirmDeleteDlg" max-width="290">
      <v-card>
        <v-card-title class="headline">Confirm Delete</v-card-title>
        <v-card-text class="body">
          ต้องการลบรายการนี้ใช่หรือไม่?
          <h3>{{ selectedItem }}</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDeleteDlg = false"> cancel </v-btn>
          <v-btn color="error" text @click="confirmDelete">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogloading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          กำลังเตรียมข้อมูล รอสักครู่..
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- bottom sheet component -->
    <OrderDetailSheet
      :id="orderIdSheet"
      @clear-id="orderIdSheet = ''"
    ></OrderDetailSheet>
    <!-- end bottom sheet component -->

    <!-- dialog payment -->
    <v-dialog v-model="dialogPayment" persistent width="300">
      <v-card>
        <v-card-title>
          <span class="subtitle-1"
            >เลือก <b>COD</b> 
            <v-chip class="ml-1" small dark color="red"
              ><b>{{ selected.length }}</b></v-chip
            >
            รายการ</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="dateCodRef"
                  label="วันที่ได้รับโอน ปี-เดือน-วัน"
                  dense
                  v-mask="maskDate"
                  :rules="[(v) => !!v || 'กรุณากรอก']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.trim="codPaidRef"
                  label="รหัสอ้างอิง โอนยอด COD"
                  dense
                  :rules="[(v) => !!v || 'กรุณากรอก']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <div class="text-center">
          <small>*กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนบันทึก</small>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogPayment = false"
            >Close</v-btn
          >
          <v-btn color="blue darken-1" text @click="savePayment">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog payment -->

     <v-dialog v-model="confirmChangeStatusDlg" max-width="290">
      <v-card>
        <v-card-title class="headline">เปลี่ยนสถานะ</v-card-title>
        <v-card-text class="body">
          ต้องการเปลี่ยนสถานะเป็น
          <h3>
            <span class="blue--text">{{ selectedStatusAction.title }}</span>
            ใช่หรือไม่?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmChangeStatusDlg = false"> cancel </v-btn>
          <v-btn color="error" text @click="doChangeStatus">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api, {PAYMENTMETHOD,TRANSPORTER,ORDERSTATUS } from "@/services/api";
import { mask } from "vue-the-mask";
import OrderCodTotalCard from "@/components/cards/OrderCodTotalCard";
import Transporter from "@/components/helpers/Transporter";
import OrderStatus from "@/components/helpers/OrderStatus";
import OrderDetailSheet from "@/components/OrderDetailSheet";

import _ from "lodash";

export default {
  name: "CodOrder",
  data() {
    return {
      filterTransporter: "",
      dateCodRef: "",
      codPaidRef: "",
      orderIdSheet: "",
      copySucceeded: null,
      dialogloading: false,
      dialogPayment: false,
      confirmChangeStatusDlg: false,
      selectedStatusAction: "",
      selected: [],
      snackbar: {
        active: false,
        msg: "",
      },
      snackbarFaild: {
        active: false,
        msg: "",
      },
      itemsMenuAction: [
        { title: "รอ", actionType: ORDERSTATUS.WAIT },
        { title: "รอจัดการ", actionType: ORDERSTATUS.PENDING },
        { title: "สำเร็จ", actionType: ORDERSTATUS.COMPLETED },
        { title: "ยกเลิก cancelled", actionType: ORDERSTATUS.CANCELLED },
      ],
      paymentMethods: [
        { id: PAYMENTMETHOD.TRANFER, name: "โอนเงิน" },
        { id: PAYMENTMETHOD.COD, name: "COD จ่ายปลายทาง" },
      ],
       transporterMenuAction: [
        {title: "เลือก", actionType: ''},
        {title: "Kerry Express", actionType: TRANSPORTER.KERRYEXPRESS},
        {title: "Flash Express", actionType: TRANSPORTER.FLASHEXPRESS}
      ],
      mDataArray: [],
      headers: [
        {
          text: "Id",
          align: "left",
          sortable: false,
          value: "id",
          width: 60,
        },
        { text: "Tracking", value: "tracking", width: 150 },
        { text: "COD Status", value: "codstatus", width: 120 },
        { text: "Amount", value: "amount" },
        { text: "Branch", value: "branch" },
        { text: "Name", value: "name" },
        // { text: "Method", value: "method" },
        { text: "เข้าระบบ / อัพเดต", value: "date" },
        { text: "ระยะเวลา", value: "daterange" },
        { text: "Action", value: "action" },
      ],
      maskDate: "####-##-##",
      search: "",
      selectedItemId: "",
      confirmDeleteDlg: false, // false เท่ากับไม่ให้แสดง
      selectedItem: "",
      dataTableLoading: true,
      totalItems: 0,
      options: {}, // for dataTable
    };
  },
  components: {
    // register ตัว components ที่เราทำการ import เข้ามาให้สามารถใช้งานได้
    OrderCodTotalCard,
    Transporter,
    OrderStatus,
    OrderDetailSheet,
  },
  directives: {
    mask,
  },
  mounted() {
    console.log("this codorder components is running");
    //this.loadData();
  },
  methods: {
    doActionMenu(actionType) {
      this.selectedStatusAction = actionType;
      this.confirmChangeStatusDlg = true;
    },
    doChangeStatus() {
      this.changeOrderStatus();
      this.confirmChangeStatusDlg = false;
    },
    async changeOrderStatus() {
      if (this.selected.length > 0) {
        let itemsSelected = this.selected;
        let ids = _.map(itemsSelected, item => {
          return item.id;
        });

        let formData = new FormData();
        formData.append("items", JSON.stringify(ids));
        formData.append("status", this.selectedStatusAction.actionType);
        let result = await api.updateBulkOrderStatus(formData);
        if (result) {
          // update success
          this.snackbar = {
            active: true,
            msg: `อัพเดตสถานะสำเร็จ`
          };

          this.selected = [];
          this.loadData();
        } else {
          // update failed
          this.snackbarFaild = {
            active: true,
            msg: `อัพเดตสถานะไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
          };
        }
      }
    },
    selectTransporter:  _.debounce(function() {
      // delay ให้กับการเลือก select
      this.loadData()

      this.$refs.codCard.loadData();
      this.$refs.paidCard.loadData();
      this.$refs.totalCard.loadData();
    },800),
    updatePayment() {
      if (this.selected.length > 0) {
        this.dialogPayment = true;
      }
    },
    async savePayment() {
      if (this.selected.length > 0) {
        let itemsSelected = this.selected;
        let ids = _.map(itemsSelected, (item) => {
          return item.id;
        });

        let formData = new FormData();
        formData.append("items", JSON.stringify(ids));
        formData.append("dateRef", this.dateCodRef);
        formData.append("paidRef", this.codPaidRef);

        let result = await api.updatBulkPaymentCODInfo(formData);
        if (result.data.result == "ok") {
          // update success
          this.snackbar = {
            active: true,
            msg: `อัพเดตข้อมูลสำเร็จ`,
          };

          this.selected = [];

          this.$refs.codCard.loadData();
          this.$refs.paidCard.loadData();
          this.$refs.totalCard.loadData();
          this.loadData();
        } else {
          // update failed
          this.snackbarFaild = {
            active: true,
            msg: `อัพข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
          };
        }
        this.dateCodRef = '';
        this.codPaidRef = '';
        this.dialogPayment = false;
      }
    },
    handleCopyStatus(status) {
      this.copySucceeded = status;
    },
    copyTracking(tracking) {
      let linktrack = "";
       if (tracking.indexOf("PSR") == 0) {
        linktrack = `https://th.kerryexpress.com/th/track/v2/?track=${tracking}`;
      } else if (tracking.indexOf("TH") == 0) {
        linktrack = `https://www.flashexpress.co.th/tracking/?se=${tracking}`;
      } else if (tracking.indexOf("ALP") == 0) {
        linktrack = "http://www.alphafast.com/th/track?id=" + tracking;
      }

      this.$copyText(linktrack).then(
        function(e) {
          // alert('Copied')
          // console.log(e)
        },
        function(e) {
          // alert('Can not copy')
          console.log(e);
        }
      );
    },
    searchItems: _.debounce(function() {
      this.options.page = 1;
      this.loadData();
    }, 2000),
    loadData() {
      this.getDataFromApi()
        .then((data) => {
          this.mDataArray = data.rows;
          this.totalItems = data.count;
          data = null;
        })
        .catch((error) => {
          this.mDataArray = [];
          // token is expired
          this.$router.push("/logout");
          console.log("myerror :", error);
        });
    },
    getDataFromApi() {
      this.dataTableLoading = true;
      this.dialogloading = true;
      return new Promise((resolve, reject) => {
        const { page, itemsPerPage } = this.options;

        api.getOrdersCOD(page, itemsPerPage, this.search, this.filterTransporter).then((result) => {
          if (typeof result.data.rows !== "undefined") {
            let { rows, count } = result.data;
            setTimeout(() => {
              this.dataTableLoading = false;
              this.dialogloading = false;
              resolve({
                rows,
                count,
              });
              rows = null;
              result = null;
            }, 500);
          } else {
            this.dataTableLoading = false;
            this.dialogloading = false;
            reject({ message: result.data.message });
          }
        });
      });
    },
    editItem(item) {
      this.$router.push(`/billing-edit/${item.id}`);
    },
    deleteItem(item) {
      this.selectedItemId = item.id;
      this.selectedItem = `รหัส: ${item.id} ${item.name}`;
      this.confirmDeleteDlg = true;
    },
    async confirmDelete() {
      let result = await api.deleteOrder(this.selectedItemId);
      if (result.data.result == "ok") {
        this.$refs.codCard.loadData();
        this.$refs.paidCard.loadData();
        this.$refs.totalCard.loadData();

        this.snackbar = {
          active: true,
          msg: "ลบข้อมูลสำเร็จ",
        };
      } else {
        this.snackbarFaild = {
          active: true,
          msg: "ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง",
        };
      }

      this.confirmDeleteDlg = false; // ซ้อนการแจ้งเตือน dialog
      this.loadData();
    },
    changePaymentMethod: _.debounce(async function(item) {
      this.dataTableLoading = true;
      let { id, is_cod } = item;
      let formData = new FormData();
      formData.append("id", id);
      formData.append("is_cod", is_cod);
      let result = await api.setOrderPaymentMethod(formData);
      if (result.data.result == "ok") {
        this.snackbar = {
          active: true,
          msg: `รหัส ${item.id} เปลี่ยนประเภทชำระเงินสำเร็จ`,
        };
      } else {
        this.snackbarFaild = {
          active: true,
          msg: `รหัส ${item.id} เปลี่ยนประเภทชำระเงินไม่สำเร็จ`,
        };
      }
      this.dataTableLoading = false;
      this.$refs.codCard.loadData();
      this.$refs.paidCard.loadData();
      this.$refs.totalCard.loadData();
      this.loadData();
    }, 1000),
  },
  watch: {
    options: {
      //เมื่อค่า options ที่เรา sync กับ datable เปลี่ยน method นี้จะทำงานทันที  watch นี้ ชื่อ method ต้องตรงกันกับตัวแปรที่ประกาศข้างบน
      handler(newValue, oldValue) {
        //console.log(this.options.page, this.options.itemsPerPage)
        this.loadData();
      },
      deep: true,
    },
    search: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          //console.log(this.dates);
          this.searchItems();
        }
      },
    },
  },
  beforeDestroy: function() {
    delete this.mDataArray;
    delete this.selected;
    delete this.options;
    delete this.headers;
  },
};
</script>

<style scoped>
.my-td-width {
  width: 120px;
}
.my-td-width2 {
  width: 220px;
}
.my-td-width3 {
  width: 150px;
}
</style>
